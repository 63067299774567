<template>

    <v-container fluid class="pa-0">
      <v-app-bar
        dense
        app
        height="50"
        scroll-target="#sheet1"
        color="white"
        flat
        fluid
        style="z-index: 200"
      >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            text
            small
            class="black--text font-weight-normal pa-2"
            @click="handleSelect('orderonline')"
            >Shop</v-btn
          >
          <v-btn
            text
            small
            class="black--text font-weight-normal pa-2"
            @click="handleSelect('ravioli')"
            >Ravioli</v-btn
          >
          <v-btn
            text
            small
            class="black--text font-weight-normal pa-2"
            @click="handleSelect('freshpasta')"
            >Pasta</v-btn
          >
          <v-btn
            text
            small
            class="black--text font-weight-normal pa-2"
            @click="handleSelect('other')"
            >Bakes & Mates</v-btn
          >
        </v-toolbar-items>
        <v-spacer />
        <v-toolbar-items>
          <v-img
            :src="logoimg"
            max-width="200"
            max-height="65"
            contain
            @click="handleSelect('home')"
          ></v-img>
        </v-toolbar-items>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            small
            class="black--text font-weight-normal pa-2"
            @click="handleSelect('wholesale')"
            >Wholesale</v-btn
          >
          <v-btn
            text
            small
            class="black--text font-weight-normal pa-2"
            @click="handleSelect('stockists')"
            >Stockists</v-btn
          >
          <v-btn
            text
            small
            class="black--text font-weight-normal pa-2"
            @click="handleSelect('aboutus')"
            >About</v-btn
          >
          <v-btn
            text
            small
            class="black--text font-weight-normal pa-2"
            @click="handleSelect('contactus')"
            >Contact</v-btn
          >
          <v-btn
            icon
            color="black"
            class="pa-1"
            @click="
              $root.preord = true;
              handleSelect('orderonline');
            "
          >
            <v-icon>mdi-cart</v-icon>
            <v-badge
              v-if="$root.noitems > 0"
              color="grey"
              class="black--text"
              :content="$root.noitems"
            ></v-badge>
          </v-btn>
          <v-btn
            small
            style="height: 30"
            v-if="$root.seclev > 10"
            text
            class="white--text mr-1"
            icon
            @click="drawer = !drawer"
          >
            <v-icon>view_headline</v-icon>
          </v-btn>
        </v-toolbar-items>
        <v-spacer></v-spacer>
      </v-app-bar>
    </v-container>

</template>

<script>
export default {
  name: "mediumdisplay",

  data() {
    return {
      drawer: false,
      mobile: true,
      grpprod: true,
      grpmst: false,
      othm: false,
      grppay: false,
      grpstk: false,
      grpfin: false,
      dlglogon: false,
      logoimg: require("@/assets/logo.jpg"),
      logicon: true,
      activeIndex: "1",
      activeIndex2: "1",
      seclev: 0,
      on: false,
      offsetTop: 0,
      web: 1,
    };
  },
  mounted() {
    //debugger;
    // this.$router
    //   .push({
    //     name: "home",
    //     params: { opcode: "", uid: "" },
    //   })
    //   .catch((e) => {
    //     if (e.name != "NavigationDuplicated") {
    //       // But print any other errors to the console
    //       console.error();
    //     }
    //   });
  },
  methods: {
    // onScroll(e) {
    //   this.offsetTop = e.target.scrollTop;
    //   if (this.offsetTop > 1000) {
    //     this.$root.showlogo = 0;
    //   } else {
    //     this.$root.showlogo = 1;
    //   }
    // },
    handleSelect(key) {
      // switch (key) {
      //   case "prodsch":
      // if (key == "home") {
      //   this.$root.showlogo = 1;
      // } else {
      //   this.$root.showlogo = 0;
      // }
      this.$router
        .push({
          name: key,
          params: {
            opcode: this.$root.opcode,
            uid: this.$root.uid,
            showfooter: 1,
          },
        })
        .catch((e) => {
          if (e.name != "NavigationDuplicated") {
            // But print any other errors to the console
            console.error();
          }
        });
      this.drawer = false;
    },
  },
};
</script>
